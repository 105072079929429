<ng-container *transloco="let t">
    <h2 mat-dialog-title>{{ data.title ?? t('SUCCESS') }}</h2>
    <mat-dialog-content>
        @if (data.text) {
            <div class="modal-text full-width">{{ data.text }}</div>
        }
        @if (data.input) {
            <mat-form-field class="input-field full-width">
                <mat-label>{{ data.input.label }}</mat-label>
                <input matInput [type]="data.input.type" [formControl]="formControl" />
                <mat-error [icpErrorLabel]="formControl"></mat-error>
            </mat-form-field>
        }
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        @if (data.cancelLabel) {
            <button mat-flat-button color="warn" matDialogClose>{{ data.cancelLabel }}</button>
        }
        <button mat-flat-button color="primary" type="button" (click)="close()">
            {{ data.closeLabel || t('OK') }}
        </button>
    </mat-dialog-actions>
</ng-container>
