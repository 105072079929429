import { inject, Injectable } from '@angular/core';
import { DEFAULT_LANGUAGE } from '@icp/angular/i18n';
import { retryWithBackoff } from '@icp/angular/rxjs-operators';
import { PlaceTypeDetailsDto, PlaceTypesService } from '@icp/interfaces';
import { Observable, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CachedPlaceTypesService {
    private placeTypesService = inject(PlaceTypesService);

    readonly placeTypes$: Observable<PlaceTypeDetailsDto[]> = this.placeTypesService
        .listByLanguage(DEFAULT_LANGUAGE)
        .pipe(retryWithBackoff(), shareReplay());
}
