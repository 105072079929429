<div class="snackbar-grid" *transloco="let t">
    <div class="message" [innerHTML]="t(data.messageKey, data.messageParams)"></div>

    @if (data.errorMessage) {
        <div class="error-messages">
            {{ data.errorMessage }}
        </div>
    }

    @if (canRetry) {
        <div class="error-retry">
            <button mat-stroked-button (click)="doRetry()">{{ t('RETRY') }}</button>
        </div>
    }
</div>
