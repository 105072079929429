import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationKey } from '@icp/angular/i18n';
import { HashMap } from '@jsverse/transloco';
import { Action, ActionCreator } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { NotificationsSnackbarComponent } from '../../shared/components/notification-snackbar/notifications-snackbar.component';
import { ErrorData } from '../model';

type ErrorActionCreator<T extends string> = ActionCreator<
    T,
    (props: { error: string }) => { error: string } & Action<T>
>;

export const DEFAULT_ERROR_SNACKBAR_DURATION = 5000;

@Injectable({ providedIn: 'root' })
export class ErrorService {
    private snackBar = inject(MatSnackBar);

    showPlainError(
        translationKey: TranslationKey,
        translationKeyParams?: HashMap,
        snackbarOptions?: { duration: number },
    ) {
        this.snackBar.openFromComponent(NotificationsSnackbarComponent, {
            data: new ErrorData(null, translationKey, undefined, undefined, translationKeyParams),
            duration: snackbarOptions?.duration ?? DEFAULT_ERROR_SNACKBAR_DURATION,
            panelClass: ['notification-snackbar', 'notification-snackbar--error'],
        });
    }

    showError(error: unknown, snackbarOptions?: { duration: number }) {
        this.snackBar.openFromComponent(NotificationsSnackbarComponent, {
            data: new ErrorData(error),
            duration: snackbarOptions?.duration ?? DEFAULT_ERROR_SNACKBAR_DURATION,
            panelClass: ['notification-snackbar', 'notification-snackbar--error'],
        });
    }

    showErrorData(errorData: ErrorData, snackbarOptions?: { duration: number }) {
        return this.snackBar.openFromComponent(NotificationsSnackbarComponent, {
            data: errorData,
            duration: snackbarOptions?.duration ?? DEFAULT_ERROR_SNACKBAR_DURATION,
            panelClass: ['notification-snackbar', 'notification-snackbar--error'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    handleError<T extends string>(
        originalAction: Action,
        failActionCreator: ErrorActionCreator<T>,
        error: unknown,
        options?: { duration?: number; canRetry?: boolean },
    ): Observable<Action> {
        const retry = options?.canRetry ?? true;
        const clickAction = retry ? originalAction : undefined;
        const errorData = new ErrorData(error, '', clickAction);
        this.showErrorData(errorData);
        return of(failActionCreator({ error: errorData.errorMessage }));
    }
}
