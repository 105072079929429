import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslocoDirective } from '@jsverse/transloco';

import { MessageConfirmData } from '../../../core/services';

@Component({
    selector: 'icp-message-confirm',
    templateUrl: 'message-confirm.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, MatDialogModule, MatButtonModule],
})
export class MessageConfirmComponent {
    data = inject<MessageConfirmData>(MAT_DIALOG_DATA);
}
