import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoDirective } from '@jsverse/transloco';

import { MessageDialogData, MessageDialogResult } from '../../../core/services';
import { IcpErrorLabelDirective } from '../../directives/error-label.directive';

@Component({
    selector: 'icp-message-modal',
    templateUrl: 'message-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: '.modal-text{ white-space: break-spaces; } .input-field { margin-top: 1rem }',
    standalone: true,
    imports: [
        TranslocoDirective,
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        ReactiveFormsModule,
        IcpErrorLabelDirective,
    ],
})
export class MessageModalComponent implements OnInit {
    public data: MessageDialogData = inject(MAT_DIALOG_DATA);
    formControl = new FormControl('', { nonNullable: true });
    private matDialogRef = inject(MatDialogRef);

    ngOnInit() {
        if (this.data.input) {
            this.formControl.addValidators(Validators.required);
        }
    }

    close() {
        if (this.formControl.valid) {
            this.matDialogRef.close(
                this.data.input ? ({ input: this.formControl.value } satisfies MessageDialogResult) : false,
            );
        } else {
            this.formControl.markAsTouched();
        }
    }
}
