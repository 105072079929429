import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { lastValueFrom } from 'rxjs';

import { MessageConfirmComponent } from '../../shared/components/message-confirm/message-confirm.component';
import { MessageModalComponent } from '../../shared/components/message-modal/message-modal.component';

export type MessageDialogData = Partial<PromptDialogData> & {
    title?: string;
    text?: string;
    cancelLabel?: string;
    closeLabel?: string;
};

export interface PromptDialogData {
    input: {
        type: HTMLInputElement['type'];
        label: string;
    };
}

export interface MessageDialogResult {
    input: string;
}

export interface MessageConfirmData {
    text: string;
    buttonConfirm?: string;
    buttonCancel?: string;
    title?: string;
}

@Injectable({ providedIn: 'root' })
export class MessageModalService {
    private transloco = inject(TranslocoService);
    private dialog = inject(MatDialog);

    async showConfirm(options: MessageConfirmData): Promise<boolean | undefined> {
        const dialog = this.dialog.open<MessageConfirmComponent, MessageConfirmData, boolean>(MessageConfirmComponent, {
            disableClose: true,
            width: '400px',
            hasBackdrop: true,
            data: options,
        });
        return lastValueFrom(dialog.afterClosed());
    }

    async showPrompt(options: MessageDialogData & Required<PromptDialogData>) {
        const dialog = this.dialog.open<MessageModalComponent, MessageDialogData, MessageDialogResult>(
            MessageModalComponent,
            {
                disableClose: true,
                width: '400px',
                hasBackdrop: true,
                data: options,
            },
        );
        return lastValueFrom(dialog.afterClosed());
    }

    async showError(options: MessageDialogData) {
        return this.dialog.open<MessageModalComponent, MessageDialogData, MessageDialogResult>(MessageModalComponent, {
            disableClose: true,
            width: '400px',
            hasBackdrop: true,
            data: {
                ...options,
                title: options.title ?? this.transloco.translate('ERROR'),
            },
        });
    }

    async showMessage(options: MessageDialogData): Promise<boolean | undefined> {
        const dialog = this.dialog.open(MessageModalComponent, {
            disableClose: true,
            width: '600px',
            hasBackdrop: true,
            data: {
                ...options,
                title: options.title ?? this.transloco.translate('SUCCESS'),
            },
        });
        return lastValueFrom(dialog.afterClosed());
    }
}
