import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslocoDirective } from '@jsverse/transloco';
import { Store } from '@ngrx/store';

import { NotificationOptions } from './model';

@Component({
    selector: 'icp-notification-snackbar',
    templateUrl: './notifications-snackbar.component.html',
    styleUrl: './notifications-snackbar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, MatButtonModule],
})
export class NotificationsSnackbarComponent {
    public sbRef = inject(MatSnackBarRef<NotificationsSnackbarComponent>);
    public data: NotificationOptions = inject(MAT_SNACK_BAR_DATA);
    private store = inject(Store);
    canRetry = !!(this.data.retryCallBack || this.data.retryAction);

    doRetry() {
        if (!this.canRetry) return;
        this.sbRef.dismiss();
        if (this.data.retryAction) return this.store.dispatch(this.data.retryAction);
        if (this.data.retryCallBack) return this.data.retryCallBack();
    }
}
