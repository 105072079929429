import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { FeatureFlag } from '../model/feature';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
    isFeatureEnabled(key: FeatureFlag) {
        return environment.features[key];
    }
}
