import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { selectStateDone } from '@icp/angular/ngrx';
import { RoleName } from '@icp/interfaces';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, Observable, of, switchMap } from 'rxjs';

import { selectActiveUser, selectUserRoles, selectUserRolesList } from '../../auth/store/auth.selectors';
import { selectNotificationsCount } from '../../pages/state/pages.feature';
import { FeatureFlag } from '../model/feature';
import { FeatureFlagsService } from './feature-flag.service';

const defaultRoutes = [
    {
        feature: FeatureFlag.REPORTING,
        userRoles: [RoleName.ECONOMY_ADMIN],
        route: ['pages', 'dashboard'],
    },
    { feature: FeatureFlag.ECONOMIES, route: ['pages', 'economies'] },
    { feature: FeatureFlag.MERCHANTS, route: ['pages', 'businesses'] },
    { feature: FeatureFlag.USERS, route: ['pages', 'users'] },
];

@Injectable({ providedIn: 'root' })
export class DefaultRouteService {
    private featureService = inject(FeatureFlagsService);
    private router = inject(Router);
    private store = inject(Store);

    getDefaultRouteTree(): Observable<UrlTree> {
        return this.store.select(selectUserRoles).pipe(
            filter(selectStateDone),
            switchMap(() => this.store.select(selectUserRolesList)),
            concatLatestFrom(() => this.store.select(selectActiveUser)),
            switchMap(([roles, user]) => {
                const featureRoutes = defaultRoutes.filter((d) => this.featureService.isFeatureEnabled(d.feature));
                if (featureRoutes.length === 1) return of(this.router.createUrlTree(featureRoutes[0].route));
                const userRoleRoute = featureRoutes.find((route) =>
                    (route.userRoles ?? []).some((rr) => roles.some((ur) => ur.name === rr)),
                );
                // No roles available - check if we have notifications and go to that page if we do.
                // Otherwise, go to the no permission page to show the user that he can't do anything yet.
                if (!userRoleRoute && user) {
                    if (user.isSuperAdmin) {
                        return of(this.router.createUrlTree(['pages', 'dashboard']));
                    }
                    return this.store
                        .select(selectNotificationsCount)
                        .pipe(
                            map((count) =>
                                this.router.createUrlTree(
                                    count ? ['/pages', 'notifications'] : ['/pages', 'no-permission'],
                                ),
                            ),
                        );
                }

                return of(
                    userRoleRoute
                        ? this.router.createUrlTree(userRoleRoute.route)
                        : this.router.createUrlTree(user ? ['/pages'] : ['/landing']),
                );
            }),
        );
    }
}
